@import url(https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css);
html, body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  /* background: #f4f4f4; */
  color: #263238;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #5a6169 !important;
  text-decoration: none !important;
}

* {
  box-sizing: border-box;
}

.headerbar-background {
  background-color: #263238;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
  color: #5a6169;
}

@media (min-width: 768px) {
  .navbar-brand {
    min-width: 200px;
  }
}

@media (min-width: 1100px) {
  .navbar-brand {
    min-width: 300px;
  }
}

.header-logo {
  width: 200px;
}

.nav-link {
  display: initial;
  padding: 1rem 0rem;
  margin-top: 10px;
}

.h1, h1 {
  font-size: 2.5rem;
}